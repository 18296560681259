import {IMediaItem, IVideoFile} from '../../src/types/app-types';

export class MediaItemBuilder {
  private readonly mediaItem: IMediaItem = {
    altText: null,
    fullUrl: '',
    height: 0,
    id: '',
    index: 0,
    mediaType: '',
    thumbnailFullUrl: '',
    title: '',
    url: '',
    videoFiles: [],
    videoType: null,
    width: 0,
  };

  public withUrl(url: string): MediaItemBuilder {
    this.mediaItem.url = url;
    return this;
  }

  public withId(id: string): MediaItemBuilder {
    this.mediaItem.id = id;
    return this;
  }

  public withFullUrl(fullUrl: string): MediaItemBuilder {
    this.mediaItem.fullUrl = fullUrl;
    return this;
  }

  public withThumbnailFullUrl(thumbnailFullUrl: string): MediaItemBuilder {
    this.mediaItem.thumbnailFullUrl = thumbnailFullUrl;
    return this;
  }

  public withMediaType(mediaType: string): MediaItemBuilder {
    this.mediaItem.mediaType = mediaType;
    return this;
  }

  public withVideoType(videoType: string): MediaItemBuilder {
    this.mediaItem.videoType = videoType;
    return this;
  }

  public withVideoFiles(videoFiles: IVideoFile[]): MediaItemBuilder {
    this.mediaItem.videoFiles = videoFiles;
    return this;
  }

  public withWidth(width: number): MediaItemBuilder {
    this.mediaItem.width = width;
    return this;
  }

  public withHeight(height: number): MediaItemBuilder {
    this.mediaItem.height = height;
    return this;
  }

  public withIndex(index: number): MediaItemBuilder {
    this.mediaItem.index = index;
    return this;
  }

  public withTitle(title: string): MediaItemBuilder {
    this.mediaItem.title = title;
    return this;
  }

  public withAltText(altText: string): MediaItemBuilder {
    this.mediaItem.altText = altText;
    return this;
  }

  public get(): IMediaItem {
    return this.mediaItem;
  }
}

export function aMediaItem(): MediaItemBuilder {
  return new MediaItemBuilder();
}

const pushToMedia = (media: IMediaItem[]) => (item, index) => {
  media.push(
    aMediaItem()
      .withId(item.id)
      .withUrl(item.u)
      .withFullUrl(item.fullUrl)
      .withThumbnailFullUrl(item.thumbnailFullUrl)
      .withMediaType(item.mediaType)
      .withVideoType(null)
      .withVideoFiles([])
      .withWidth(item.w)
      .withHeight(item.h)
      .withAltText(item.a)
      .withIndex(index)
      .withTitle(item.title)
      .get()
  );
};

export const defaultProductMediaImage: IMediaItem = aMediaItem()
  .withUrl('eaa00d_b02f06cef98b454c8b56a1dcebdacee4.jpg')
  .withMediaType('PHOTO')
  .withTitle('')
  .withIndex(1)
  .withWidth(800)
  .withHeight(800)
  .get();

export function aDefaultOneVideoMediaItem() {
  const media = [];
  const defaults = [
    {
      u: '6086c4_3f99f4a7c1b6473f8059c972fde4625ff002.jpg',
      w: 1354,
      h: 901,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      thumbnailFullUrl:
        'https://static.wixstatic.com/media/697bc8_49e72a53cd064655ba1991bf1d7c1deb~mv2_d_1920_1920_s_2.jpg/v1/fit/w_50,h_50,q_90/file.jpg',
      mediaType: 'PHOTO',
      title: 'image',
    },
  ];

  defaults.forEach(pushToMedia(media));

  const video: any = {
    videoFiles: [
      {
        height: 480,
        width: 854,
        url: 'video/6086c4_3f99f4a7c1b6473f8059c972fde4625f/480p/mp4/file.mp4',
        quality: '480p',
      },
      {
        height: 720,
        width: 1280,
        url: 'video/6086c4_3f99f4a7c1b6473f8059c972fde4625f/720p/mp4/file.mp4',
        quality: '720p',
      },
    ],
    videoType: 'WIXVID',
    mediaType: 'VIDEO',
  };

  Object.assign(media[0], video);

  return media;
}

export function aDefaultMediaItems(): IMediaItem[] {
  const media = [];
  const defaults = [
    {
      u: 'f1450fb71bd94739ddfee7a0d629535b.jpg',
      w: 1280,
      h: 954,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: 'db16421629c7b865994596f88447c313.jpg',
      w: 1204,
      h: 957,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: '6086c4_3f99f4a7c1b6473f8059c972fde4625ff002.jpg',
      w: 1354,
      h: 901,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },
    {
      u: 'fbba59c61dae65c5b47ab02c51457085.jpg',
      w: 1280,
      h: 854,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: 'e3c46d674cc992e1063ce3139690f3f6.jpg',
      w: 876,
      h: 1000,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: '6086c4_c24b7801a16b4bbd8baeacc69b1120eef002.jpg',
      w: 320,
      h: 560,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: '143b40b4f279d127f332fe939843b767.jpg',
      w: 957,
      h: 1280,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: '1907b8f6ee2ab9086e40a464861c2d40.jpg',
      w: 1000,
      h: 667,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: '037a2cd4ead948d0b60a8b493f0e3044.jpg',
      w: 1000,
      h: 667,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },

    {
      u: 'b407db0270d90277489d1ba43b4b5acd.jpg',
      w: 1354,
      h: 901,
      a: 'a',
      id: '697bc8_49e72a53cd064655ba1991bf1d7c1',
      fullUrl: 'wwww',
      mediaType: 'PHOTO',
      title: 'image',
    },
  ];

  defaults.forEach(pushToMedia(media));

  const legacyVideoFormat: any = {
    videoFiles: [
      {
        height: 480,
        width: 854,
        url: 'video/6086c4_3f99f4a7c1b6473f8059c972fde4625f/480p/mp4/file.mp4',
        quality: '480p',
      },
      {
        height: 720,
        width: 1280,
        url: 'video/6086c4_3f99f4a7c1b6473f8059c972fde4625f/720p/mp4/file.mp4',
        quality: '720p',
      },
    ],
    videoType: 'WIXVID',
    mediaType: 'VIDEO',
  };

  const video: any = {
    videoFiles: [
      {
        format: 'mp4',
        height: 320,
        width: 560,
        url: 'video/6086c4_c24b7801a16b4bbd8baeacc69b1120ee/360p/mp4/file.mp4',
        quality: '360p',
      },
      {
        format: 'dash',
        url: '/files/video/0415c2_a97f6e52b41444fa8a5432737bcf44a0/repackage/dash',
      },
    ],
    videoType: 'WIXVID',
    mediaType: 'VIDEO',
  };

  Object.assign(media[2], legacyVideoFormat);
  Object.assign(media[5], video);

  return media;
}
