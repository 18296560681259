import React from 'react';
import s from './NumberInputSpinner.scss';
import classnames from 'classnames';

export interface NumberInputSpinnerProps {
  isFocused?: boolean;
  max?: number;
  min?: number;
  step?: number;
  title?: string;
  value: number;
  onChange: Function;
}

const NumberInputSpinnerDefaultProps: Partial<NumberInputSpinnerProps> = {
  isFocused: false,
  max: Infinity,
  min: 1,
  step: 1,
  title: '',
};

export class NumberInputSpinner extends React.Component<NumberInputSpinnerProps> {
  public numberInputSpinnerRef = React.createRef<HTMLInputElement>();
  public static defaultProps = NumberInputSpinnerDefaultProps;

  public componentDidMount(): void {
    if (this.props.isFocused) {
      this.focusNumberInputSpinner();
    }
  }

  private readonly focusNumberInputSpinner = () => {
    this.numberInputSpinnerRef.current.focus();
  };

  private readonly onUp = () => {
    const {value, max, step, onChange} = this.props;
    const nextValue = Math.min(value + step, max);
    onChange(nextValue);
  };

  private readonly onDown = () => {
    const {value, min, step, onChange} = this.props;
    const nextValue = Math.max(value - step, min);
    onChange(nextValue);
  };

  private readonly onChange = (e) => {
    const {value: currentValue, onChange} = this.props;
    const userInputValue = Number(e.target.value);
    const nextValue = isNaN(userInputValue) || e.target.value === '' ? currentValue : userInputValue;
    onChange(nextValue);
  };

  public render(): JSX.Element {
    const {title, max, min, value} = this.props;

    return (
      <div>
        <label data-hook="number-input-spinner-title" className={s.label}>
          {title}
        </label>
        <div>
          <div className={s.inputGroup} data-hook="number-input-spinner-container">
            <input
              type="number"
              pattern="[0-9]*"
              data-hook="number-input-spinner-input"
              value={value}
              aria-label={title}
              onChange={this.onChange}
              ref={this.numberInputSpinnerRef}
              max={max}
              min={min}
            />
            <div data-hook="number-input-spinner-arrows-container" className={s.spinnerArrows} aria-hidden={true}>
              <span
                data-hook="number-input-spinner-up-arrow"
                className={classnames(s.upArrow, {[s.disabled]: value >= max})}
                onClick={this.onUp}
              />
              <span
                data-hook="number-input-spinner-down-arrow"
                className={classnames(s.downArrow, {[s.disabled]: value <= min})}
                onClick={this.onDown}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
