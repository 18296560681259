import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {get, pick} from 'lodash';

export interface ProductPriceContainerProps extends ProvidedGlobalProps {
  className?: string;
}

const priceKeys = [
  'price',
  'formattedPrice',
  'comparePrice',
  'formattedComparePrice',
  'formattedPricePerUnit',
  'pricePerUnit',
];

@withGlobalProps
export class ProductPriceContainer extends React.Component<ProductPriceContainerProps> {
  public render(): JSX.Element {
    let prices, pricePerUnitProps;
    const {
      className,
      globals: {product, selectedVariant},
    } = this.props;

    const selectedPlan = get(this.props.globals.userInputs.subscriptionPlan, ['0']);
    prices = pick(
      selectedVariant?.subscriptionPlans?.[selectedPlan?.id] ||
        selectedVariant ||
        product.subscriptionPlans?.list.find((plan) => plan.id === selectedPlan?.id) ||
        product,
      priceKeys
    );

    if (product.pricePerUnitData) {
      pricePerUnitProps = {
        pricePerUnit: prices.pricePerUnit,
        formattedPricePerUnit: prices.formattedPricePerUnit,
        baseMeasurementUnit: product.pricePerUnitData.baseMeasurementUnit,
        baseQuantity: product.pricePerUnitData.baseQuantity,
      };
    }

    if (prices.comparePrice) {
      prices = {
        price: prices.comparePrice,
        formattedPrice: prices.formattedComparePrice,
        comparePrice: prices.price,
        formattedComparePrice: prices.formattedPrice,
      };
    }

    return <ProductPrice {...prices} {...pricePerUnitProps} plan={selectedPlan} className={className} />;
  }
}
