import React from 'react';
import {Popover} from 'wix-ui-core/popover';
import st from './ErrorTooltip.st.css';
import cx from 'classnames';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';

export interface ErrorTooltipProps extends ProvidedGlobalProps {
  message: string;
}

@withGlobalProps
export class ErrorTooltip extends React.Component<ErrorTooltipProps> {
  public render(): JSX.Element {
    const {
      message,
      globals: {errorPlacement},
    } = this.props;
    const classNames = cx({...(st as any)(errorPlacement)}.className, {...(st as any)('error')}.className);

    return (
      <Popover className={classNames} placement={errorPlacement} shown={true} showArrow={true}>
        <Popover.Content>
          <span data-hook="error-message">{message}</span>
        </Popover.Content>
      </Popover>
    );
  }
}
