import React from 'react';
import s from './ProductColor.scss';
import {ColorSampleGroup} from '@wix/wixstores-client-common-components/dist/src/ColorSampleGroup/ColorSampleGroup';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {IProductOption} from '@wix/wixstores-graphql-schema/dist/es/src';
import {IProductOptionSelectionItem} from '@wix/wixstores-client-core/dist/es/src/types/product';

export interface ProductColorsProps {
  options: IProductOption;
  allowMultiple?: boolean;
  error?: JSX.Element;
  onSelect?: Function;
  selected?: IProductOptionSelectionItem[];
}

@withGlobalProps
export class ProductColors extends React.Component<ProductColorsProps & ProvidedGlobalProps> {
  public static defaultProps = {
    allowMultiple: false,
    selected: [],
  };

  public readonly onSelect = (selected: IProductOptionSelectionItem[]): void => {
    /* istanbul ignore else: todo: test */
    if (this.props.onSelect) {
      this.props.onSelect(selected);
    }
  };

  public readonly renderDescription = (): string => {
    const {selected} = this.props;

    if (!selected.length) {
      return null;
    }

    return `: ${selected.map((selection: IProductOptionSelectionItem) => selection.description).join(', ')}`;
  };

  private renderError() {
    const {error} = this.props;

    if (!error) {
      return null;
    }

    return error;
  }

  public render(): JSX.Element {
    const {
      options: {title, selections},
      allowMultiple,
      selected,
      globals: {isRTL, isMobile, isQuickView},
    } = this.props;

    return (
      <section>
        <div data-hook="product-colors-title" className={s.description}>
          {title}
          {this.renderDescription()}
        </div>
        <div className={s.actionable}>
          {!isRTL && !isMobile && !isQuickView && this.renderError()}
          <ColorSampleGroup
            colorGroupClassName={s.colorSamplerGroup}
            allowMultiple={allowMultiple}
            onSelect={this.onSelect}
            options={selections as IProductOptionSelectionItem[]}
            selected={selected}
            colorSampleClassName={s.colorSampler}
          />
          {(isRTL || isMobile || isQuickView) && this.renderError()}
        </div>
      </section>
    );
  }
}
