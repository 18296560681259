import * as React from 'react';
import classNames from 'classnames';
import style from './Thumbnail.scss';
import {Play} from '../../../../../icons/dist';
import {IMediaItem} from '../../../../../types/app-types';

export enum DataHook {
  thumbnail = 'thumbnail-container',
  button = 'thumbnail-button',
  image = 'thumbnail-image',
  video = 'video-overlay',
}

interface ForwardRefProps {
  forwardedRef: React.RefObject<HTMLButtonElement>;
}

export interface InnerThumbnailProps {
  mediaItem: IMediaItem;
  index: number;
  isSelected: boolean;
  isVertical: boolean;
  imgUrl: string;

  handleClick(index: number): void;
  handleKeypress(e: React.KeyboardEvent<HTMLButtonElement>, index: number): void;
}

export type ThumbnailProps = InnerThumbnailProps & ForwardRefProps;

class Thumbnail extends React.Component<ThumbnailProps> {
  public render(): JSX.Element {
    const {index, isVertical, isSelected, mediaItem, imgUrl, forwardedRef} = this.props;
    const thumbnailClasses = classNames(style.thumbnail, {[style.isVerticalThumbnail]: isVertical});
    const thumbnailButtonClasses = classNames(style.thumbnailButton, {[style.thumbnailSelected]: isSelected});

    return (
      <div data-hook={DataHook.thumbnail} className={thumbnailClasses} key={`media-item-thumbnail-${index}`}>
        <button
          type="button"
          ref={forwardedRef}
          data-hook={DataHook.button}
          onClick={() => this.props.handleClick(index)}
          onKeyPress={(e: React.KeyboardEvent<HTMLButtonElement>) => this.props.handleKeypress(e, index)}
          onKeyDownCapture={
            /* istanbul ignore next: todo: test */ (e: React.KeyboardEvent<HTMLButtonElement>) =>
              this.props.handleKeypress(e, index)
          }
          className={thumbnailButtonClasses}>
          {mediaItem.mediaType === 'VIDEO' && (
            <div className={style.videoOverlay} data-hook={DataHook.video}>
              <Play />
            </div>
          )}
          <img alt={mediaItem.altText} data-hook={DataHook.image} src={imgUrl} />
        </button>
      </div>
    );
  }
}

function withRef(Component) {
  class CompWithRef extends React.Component<ThumbnailProps> {
    public render(): JSX.Element {
      const {forwardedRef, ...rest} = this.props;
      return <Component forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props: InnerThumbnailProps, ref: React.RefObject<HTMLButtonElement>) => {
    return <CompWithRef {...props} forwardedRef={ref} />;
  });
}

export const ThumbnailWithRef = withRef(Thumbnail);
