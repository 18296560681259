import React from 'react';
import {ProductColors} from './ProductColors/ProductColors';
import {NumberInputSpinner} from './NumberInputSpinner/NumberInputSpinner';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import s from './ProductOptions.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {TextOption} from './OptionInputText/TextOption';
import {OptionsDropdown} from './OptionsDropdown/OptionsDropdown';
import {UserInputType} from '../../constants';
import {
  selectProductOptionItems,
  inStock,
  hasVariantInStock,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {isFunction, isArray} from 'util';
import {ErrorTooltip} from './ErrorTooltip/ErrorTooltip';
import {ProductType} from '@wix/wixstores-client-core/dist/src/types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProductOption} from '@wix/wixstores-graphql-schema';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src';
import {SubscriptionPlans} from './SubscriptionPlans/SubscriptionPlans';
import {IProductOptionSelectionItem} from '@wix/wixstores-client-core/dist/es/src/types/product';
/* eslint-disable react/jsx-no-useless-fragment */

export interface ProductOptionsProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  shouldShowQuantity: boolean;
}

interface ProductOptionsState {
  addedToWishlist: boolean;
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductOptions extends React.Component<ProductOptionsProps, ProductOptionsState> {
  public state = {
    addedToWishlist: false,
  };

  public handleUserInput = (inputType: UserInputType, index: number) => (
    data: IProductOptionSelectionItem[] | string
  ): void => {
    const {handleUserInput, validate} = this.props.globals;

    const input = isArray(data) ? data[0] : data;

    /* istanbul ignore else: todo: test */
    if (isFunction(handleUserInput)) {
      handleUserInput(inputType, input, index);
    }

    validate();
  };

  private options() {
    const {product} = this.props.globals;

    return selectProductOptionItems(product);
  }

  private renderError(inputType: UserInputType, index: number, message: string): JSX.Element {
    const {isProductSubmitted} = this.props.globals;

    if (!isProductSubmitted) {
      return null;
    }

    const {
      globals: {userInputErrors},
    } = this.props;

    if (userInputErrors[inputType][index]) {
      return <ErrorTooltip message={message} />;
    }

    return null;
  }

  private renderOptions(): JSX.Element {
    const {
      t,
      globals: {userInputs},
    } = this.props;
    return (
      <>
        {this.options().map(
          //eslint-disable-next-line array-callback-return
          (optionItem, i): JSX.Element => {
            switch (optionItem.optionType) {
              case ProductOptionType.COLOR:
                return (
                  <Cell className={s.colors} key={`product-options-${i}`}>
                    <ProductColors
                      options={optionItem as IProductOption}
                      onSelect={this.handleUserInput(UserInputType.Selection, i)}
                      selected={userInputs[UserInputType.Selection][i] ? [userInputs[UserInputType.Selection][i]] : []}
                      error={this.renderError(
                        UserInputType.Selection,
                        i,
                        t('SELECT_OPTION_WARNING', {optionTitle: optionItem.title})
                      )}
                    />
                  </Cell>
                );
              case ProductOptionType.DROP_DOWN:
                return (
                  <Cell key={`product-options-${i}`}>
                    <OptionsDropdown
                      dropdown={optionItem}
                      onSelect={this.handleUserInput(UserInputType.Selection, i)}
                      selected={userInputs[UserInputType.Selection][i]}
                    />
                    {this.renderError(
                      UserInputType.Selection,
                      i,
                      t('SELECT_OPTION_WARNING', {optionTitle: optionItem.title})
                    )}
                  </Cell>
                );
            }
          }
        )}
      </>
    );
  }

  private renderQuantity() {
    const {
      t,
      globals: {product, quantityRange, userInputs},
    } = this.props;

    const shouldShowQuantity = this.props.shouldShowQuantity && product.productType === ProductType.PHYSICAL;
    const quantity = userInputs[UserInputType.Quantity][0];

    if (!shouldShowQuantity) {
      return null;
    }

    let error = null;
    if (quantity > quantityRange.max) {
      error = this.renderError(
        UserInputType.Quantity,
        0,
        t('QUANTITY_EXCEEDS_INVENTORY', {inventory: quantityRange.max})
      );
    } else {
      error = this.renderError(
        UserInputType.Quantity,
        0,
        t('QUANTITY_BELOW_MINIMUM_RANGE', {minimum: quantityRange.min})
      );
    }

    return (
      <Cell className={s.quantity}>
        <NumberInputSpinner
          max={quantityRange.max}
          value={quantity}
          title={t('QUANTITY_LABEL')}
          onChange={this.handleUserInput(UserInputType.Quantity, 0)}
        />
        {error}
      </Cell>
    );
  }

  private renderCustomText() {
    const {
      t,
      globals: {
        product: {customTextFields},
      },
    } = this.props;

    return customTextFields.map((customTextField, index) => {
      return (
        <Cell key={index}>
          <TextOption
            title={customTextField.title}
            isRequired={customTextField.isMandatory}
            value={''}
            maxLength={customTextField.inputLimit}
            handleOnChange={this.handleUserInput(UserInputType.Text, index)}
          />
          {this.renderError(UserInputType.Text, index, t('REQUIRED_FIELD'))}
        </Cell>
      );
    });
  }

  private renderSubscriptionPlans(): JSX.Element {
    return (
      <Cell>
        <SubscriptionPlans />
      </Cell>
    );
  }

  public render(): JSX.Element {
    const {
      globals: {
        product,
        product: {customTextFields},
        shouldShowSubscriptionPlans,
      },
    } = this.props;
    const shouldShowInputs = inStock(product) || hasVariantInStock(product);

    return (
      <div data-hook="product-options" className={s.productOptionsContainer}>
        {shouldShowInputs && (
          <div data-hook="product-options-inputs">
            {this.renderOptions()}
            {customTextFields && this.renderCustomText()}
            {this.renderQuantity()}
          </div>
        )}
        {shouldShowSubscriptionPlans && this.renderSubscriptionPlans()}
      </div>
    );
  }
}
