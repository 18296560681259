import React, {Component} from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISubscriptionPlan, SubscriptionPlan} from './SubscriptionPlan/SubscriptionPlan';
import s from './SubscriptionPlans.scss';
import {UserInputType} from '../../../constants';
import {get} from 'lodash';
import {ErrorTooltip} from '../ErrorTooltip/ErrorTooltip';

export const enum SubscriptionPlansDataHooks {
  TITLE = 'plans-title',
  Container = 'subscription-plans-container',
}

export interface ISubscriptionPlansState {
  plans: ISubscriptionPlan[];
}

export interface ISubscriptionPlansProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

@withGlobalProps
@withTranslations('globals.texts')
export class SubscriptionPlans extends Component<ISubscriptionPlansProps, ISubscriptionPlansState> {
  private onItemClicked(item: ISubscriptionPlan) {
    this.handleUserInput(item);
  }

  public handleUserInput = (input: ISubscriptionPlan): void => {
    const {handleUserInput} = this.props.globals;
    handleUserInput && handleUserInput(UserInputType.SubscriptionPlan, input, 0);
    this.props.globals.validate();
  };

  private isSelected(item: ISubscriptionPlan) {
    const subscriptionPlan = get(this.props.globals.userInputs.subscriptionPlan, ['0']);

    if (!subscriptionPlan) {
      return false;
    }

    if (subscriptionPlan.isOneTimePurchase && item.isOneTimePurchase) {
      return true;
    }

    return subscriptionPlan.id === item.id;
  }

  private renderError(): JSX.Element {
    const {isProductSubmitted} = this.props.globals;
    if (!isProductSubmitted) {
      return null;
    }

    const {
      t,
      globals: {userInputErrors},
    } = this.props;

    if (userInputErrors.subscriptionPlan[0]) {
      return <ErrorTooltip message={t('PRODUCT_PAGE_PLAN_MISSING_ERROR_TOOLTIP')} />;
    }

    return null;
  }

  public render(): JSX.Element {
    const {
      t,
      globals: {subscriptionPlans, selectedVariant},
    } = this.props;

    return (
      <div data-hook={SubscriptionPlansDataHooks.Container}>
        <div className={s.title} data-hook={SubscriptionPlansDataHooks.TITLE}>
          {t('PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE')}
          {this.renderError()}
        </div>
        {subscriptionPlans.map((item, index) => {
          return (
            <SubscriptionPlan
              key={index}
              className={s.planItem}
              plan={item}
              planVariantFormattedPrice={
                selectedVariant?.subscriptionPlans?.[item.id]?.formattedPrice || selectedVariant?.formattedPrice
              }
              isSelected={this.isSelected(item)}
              onClick={() => {
                this.onItemClicked(item);
              }}
            />
          );
        })}
      </div>
    );
  }
}
