import React from 'react';
import {SocialVendor} from '../../../constants';
import styles from './SocialIcon.scss';
import {Facebook} from '../../../icons/dist/components/Facebook';
import {Twitter} from '../../../icons/dist/components/Twitter';
import {Pinterest} from '../../../icons/dist/components/Pinterest';
import {Whatsapp} from '../../../icons/components/Whatsapp';

export interface SocialIconProps {
  className?: string;
  label: string;
  onClick?: Function;
  type: SocialVendor;
}

const socialIconMap = {
  [SocialVendor.WhatsApp]: Whatsapp,
  [SocialVendor.Facebook]: Facebook,
  [SocialVendor.Pinterest]: Pinterest,
  [SocialVendor.Twitter]: Twitter,
};

export class SocialIcon extends React.PureComponent<SocialIconProps> {
  private readonly onClick = (e): void => {
    const {onClick} = this.props;

    if (onClick) {
      onClick(e);
    }
  };

  public render(): JSX.Element {
    const {type, label, className} = this.props;
    const Icon = socialIconMap[type];
    const classnames = styles[type];

    return (
      <a onClick={this.onClick} title={label} className={className} href="#">
        <Icon className={classnames} />
      </a>
    );
  }
}
