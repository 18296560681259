import {SocialVendor} from '../../constants';
import {ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {IMediaItem, IProductDTO} from '../../types/app-types';
import {defaultProductMediaImage} from '../../../test/builders/mediaItems.builder';

export function getDefaultProductMedia(product: IProductDTO): IMediaItem {
  return /* istanbul ignore next: todo: test */ product.media && product.media.length > 0
    ? product.media[0]
    : defaultProductMediaImage;
}

export function shouldRenderVendorFromStyledParams(vendor: SocialVendor, props: ProvidedGlobalProps): boolean {
  const {
    globals: {
      style: {
        styleParams: {booleans},
      },
    },
  } = props;

  const styledKey = `productPage_socialNetwork${vendor}`;

  return booleans[styledKey];
}
