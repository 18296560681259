import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import cx from 'classnames';
import s from './ModalGallery.scss';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {CloseWithBackground} from '../../icons/dist/components/CloseWithBackground';
import {timeout} from './lib/utils';
import {VIDEO_PROPS, IMAGE_PROPS} from './lib/constants';
import {IMediaItem} from '../../types/app-types';

export interface ModalGalleryBaseProps {
  currentIndex: number;
  handleClose(): void;
  handleMount?(): void;
  handleNavigateTo(index: number): void;
  media: IMediaItem[];
}

export interface ModalGalleryBaseState {
  height: number;
  inTransition: boolean;
  width: number;
  zoom: Zoom;
  zoomOffset: ZoomAxis;
}

export enum Hooks {
  ArrowNext = 'modal-gallery-arrow-next',
  ArrowPrev = 'modal-gallery-arrow-prev',
  Close = 'modal-gallery-close',
  Container = 'modal-gallery-container',
  DotNavigation = 'modal-gallery-dots',
  Header = 'modal-gallery-header',
  MediaNode = 'modal-gallery-media-node',
  Root = 'modal-gallery-root',
}

const enum ZoomFactor {
  ACTIVE = 2,
  INACTIVE = 1,
}

export const enum PinchType {
  IN = 'IN',
  OUT = 'OUT',
}

export const enum ZoomDPR {
  DESKTOP = 1,
  MOBILE = 2,
}

export const enum ZoomDirection {
  DESKTOP = -1,
  MOBILE = 1,
}

export const enum Zoom {
  ON = 'ON',
  OFF = 'OFF',
}

interface ZoomAxis {
  x: number;
  y: number;
}

const defaultDimensions = {
  height: 500,
  width: 500,
};

export class ModalGalleryBase extends React.Component<ModalGalleryBaseProps, ModalGalleryBaseState> {
  protected currentAxis: ZoomAxis = {x: 0, y: 0};
  protected direction: ZoomDirection;
  protected dpr: ZoomDPR;
  protected readonly mediaOptions = {isSEOBot: true};
  public root = React.createRef<HTMLDivElement>();
  public warmed: string[] = [];

  public state = {
    ...defaultDimensions,
    inTransition: false,
    zoom: Zoom.OFF,
    zoomOffset: {x: 0, y: 0},
  };

  protected get gestures() {
    return {};
  }

  protected bindEvents() {
    return;
  }

  protected unbindEvents() {
    return;
  }

  protected renderArrows() {
    return null;
  }

  protected get margins(): {x: number; y: number} {
    return {x: 0, y: 0};
  }

  protected async setDimensions(): Promise<void> {
    return new Promise((resolve) => {
      this.setState(
        {
          width: this.root.current.offsetWidth || defaultDimensions.width,
          height: this.root.current.offsetHeight || defaultDimensions.height,
        },
        resolve
      );
    });
  }

  protected warmUpAllMedia(): void {
    this.props.media.forEach((m) => this.warmUp(m, ZoomFactor.INACTIVE));
  }

  protected onMount = (): void => {
    //
  };

  public async componentDidMount(): Promise<void> {
    document.body.classList.add(s.fixed);
    this.bindEvents();
    this.onMount();
    await this.setDimensions();
    this.warmUpAllMedia();
    this.props.handleMount && this.props.handleMount();
  }

  public componentWillUnmount(): void {
    document.body.classList.remove(s.fixed);
    this.unbindEvents();
  }

  public render(): JSX.Element {
    return ReactDOM.createPortal(
      <div>
        {this.renderHeader()}
        <div data-hook={Hooks.Root} className={s.modal} ref={this.root}>
          {this.renderContainer()}
          {!this.isZoomed && this.renderFooter()}
        </div>
      </div>,
      document.body
    );
  }

  protected getMediaScales(media: IMediaItem, zoom: number): {width: number; height: number} {
    const {height: containerHeight, width: containerWidth} = this.state;
    const limitedContainerHeight = containerHeight - this.margins.y;
    const limitedContainerWidth = containerWidth - this.margins.x;

    const mediaRatio = media.width / media.height;
    const containerRatio = limitedContainerWidth / limitedContainerHeight;
    const scale =
      mediaRatio > containerRatio ? media.width / limitedContainerWidth : media.height / limitedContainerHeight;

    return {
      width: Math.ceil((media.width / scale) * zoom),
      height: Math.ceil((media.height / scale) * zoom),
    };
  }

  protected get currentMedia() {
    return this.props.media[this.props.currentIndex];
  }

  protected renderContainer() {
    const src = this.getMediaSrc(this.currentMedia, this.zoomFactor);
    if (!src) {
      return;
    }
    this.warmUp(this.currentMedia, ZoomFactor.ACTIVE);

    return (
      <div className={s.box}>
        {!this.isZoomed && this.renderArrows()}
        <div
          key={`modal-gallery-media-${this.currentMedia.url}`}
          className={s.container}
          data-hook={Hooks.Container}
          data-zoomed={this.isZoomed}>
          {this.renderMedia()}
        </div>
      </div>
    );
  }

  protected get zoomFactor() {
    return this.isZoomed ? ZoomFactor.ACTIVE : ZoomFactor.INACTIVE;
  }

  protected renderMedia() {
    const {zoomOffset, inTransition} = this.state;
    const mediaScales = this.currentMediaScales();
    const src = this.getMediaSrc(this.currentMedia, this.zoomFactor);
    const nextX = -mediaScales.width / 2 - zoomOffset.x * this.direction;
    const nextY = -mediaScales.height / 2 - zoomOffset.y * this.direction;
    const transform = `translate3d(${nextX}px, ${nextY}px, 0)`;
    const isVideo = this.currentMedia.mediaType === 'VIDEO';
    const className = cx({
      [s.media]: true,
      [s.zoomOut]: this.isZoomed,
      [s.zoomIn]: !this.isZoomed,
      [s.inTransition]: inTransition,
    });
    return React.createElement(isVideo ? 'video' : 'img', {
      'data-hook': Hooks.MediaNode,
      'data-axis': `${zoomOffset.x}-${zoomOffset.y}`,
      src,
      className,
      draggable: false,
      height: mediaScales.height,
      style: {transform},
      width: mediaScales.width,
      ...(isVideo ? VIDEO_PROPS : IMAGE_PROPS),
      ...this.gestures,
    });
  }

  protected getMediaSrc(media: IMediaItem, zoom: ZoomFactor) {
    return media.mediaType === 'PHOTO'
      ? this.getMediaUrl(media, zoom)
      : `https://video.wixstatic.com/${media.videoFiles[0].url}`;
  }

  protected renderHeader() {
    return (
      <header className={s.header} data-hook={Hooks.Header}>
        <a data-hook={Hooks.Close} onClick={(e) => this.handleClose(e)} className={s.close}>
          <CloseWithBackground />
        </a>
      </header>
    );
  }

  protected setAxis({x, y}: ZoomAxis) {
    const currentMediaScales = this.currentMediaScales();
    const boundryX = Math.abs((this.state.width - currentMediaScales.width) / 2) - 2;
    const boundryY = Math.abs((this.state.height - currentMediaScales.height) / 2) + 2;
    const nextX = this.state.width > currentMediaScales.width ? this.currentAxis.x : x;
    const zoomOffset = {x: this.currentAxis.x - nextX, y: this.currentAxis.y - y};

    if (zoomOffset.x < -boundryX) {
      zoomOffset.x = -boundryX;
    }
    if (zoomOffset.x > boundryX) {
      zoomOffset.x = boundryX;
    }
    if (zoomOffset.y < -boundryY) {
      zoomOffset.y = -boundryY;
    }
    if (zoomOffset.y > boundryY) {
      zoomOffset.y = boundryY;
    }

    this.setState({zoomOffset});
  }

  protected handleClose(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleClose();
  }

  protected renderFooter() {
    const {media, currentIndex} = this.props;

    if (media.length <= 1) {
      return;
    }

    return (
      <footer>
        <ul data-hook={Hooks.DotNavigation} className={s.dots}>
          {media.map((m, i) => (
            <li key={`${m.url}-${i}`} className={cx({[s.navigation]: true, [s.selected]: currentIndex === i})}></li>
          ))}
        </ul>
      </footer>
    );
  }

  protected get isZoomed() {
    return this.state.zoom === Zoom.ON;
  }

  protected currentMediaScales(): {width: number; height: number} {
    const {media, currentIndex} = this.props;
    return this.getMediaScales(media[currentIndex], this.zoomFactor);
  }

  protected readonly setZoom = _.debounce((zoom: Zoom, nextAxis: ZoomAxis = {x: 0, y: 0}) => {
    this.currentAxis = nextAxis;
    this.setAxis(nextAxis);
    this.setState({zoom});
  }, 40);

  protected navigatePrev() {
    const {currentIndex, media} = this.props;
    const to = currentIndex > 0 ? currentIndex - 1 : media.length - 1;
    this.handleNavigateTo(to);
  }

  protected navigateNext() {
    const {currentIndex, media} = this.props;
    const to = currentIndex < media.length - 1 ? currentIndex + 1 : 0;
    this.handleNavigateTo(to);
  }

  protected handleNavigateTo(n: number) {
    const {handleNavigateTo, currentIndex} = this.props;
    if (n === currentIndex) {
      return;
    }

    this.setState({inTransition: true});
    timeout(() => {
      handleNavigateTo(n);
      timeout(() => this.setState({inTransition: false}));
    }, 200);
  }

  protected getMediaUrl(mediaItem: IMediaItem, zoomFactor: ZoomFactor) {
    const scale = this.getMediaScales(mediaItem, zoomFactor);
    const scaleWithDPR = {width: scale.width * this.dpr, height: scale.height * this.dpr};
    return getMediaUrl(mediaItem, scaleWithDPR, this.mediaOptions);
  }

  protected warmUp(mediaItem: IMediaItem, zoomFactor: ZoomFactor) {
    const url = this.getMediaUrl(mediaItem, zoomFactor);
    if (!this.warmed.includes(url)) {
      this.warmed.push(url);
      new Image().src = url;
    }
  }
}
