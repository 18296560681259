import * as React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {ModalGalleryBaseProps} from './ModalGalleryBase';
import {BILoggerEvents} from '../../types/app-types';
import {ModalGalleryMobile} from './ModalGalleryMobile';
import {ModalGalleryDesktop} from './ModalGalleryDesktop';

export interface ZoomModalManager {
  isEnabled: boolean;
  open(): void;
}

interface ModalGalleryLayoutProps extends ProvidedGlobalProps {
  children(zoomModal: ZoomModalManager): JSX.Element;
}

interface ModalGalleryLayoutState {
  modalGalleryCurrentIndex: number;
  isModalGalleryOpen: boolean;
}

@withGlobalProps
export class ModalGalleryLayout extends React.Component<ModalGalleryLayoutProps, ModalGalleryLayoutState> {
  public state = {
    modalGalleryCurrentIndex: 0,
    isModalGalleryOpen: false,
  };

  private readonly open = () => {
    this.reportBI('clickToOpenZoomModalSf');
    this.setState({isModalGalleryOpen: true});
  };

  private readonly close = () => {
    this.reportBI('zoomModalIsClosedSf');
    this.setState({isModalGalleryOpen: false, modalGalleryCurrentIndex: 0});
  };

  private readonly onMount = () => {
    this.reportBI('viewZoomModalSf');
  };

  private readonly handleNavigateTo = (i: number) => {
    const value = this.state.modalGalleryCurrentIndex > i ? 'previous' : 'next';
    this.reportBI('pictureNavigationInZoomModalSf', {value});
    this.setState({modalGalleryCurrentIndex: i});
  };

  private readonly reportBI = (event: BILoggerEvents, params = {}) => {
    const {biLogger, product} = this.props.globals;
    biLogger(event, {
      productId: product.id,
      productType: product.productType,
      hasOptions: product.options.length > 0,
      ...params,
    });
  };

  private renderModal() {
    const {
      globals: {product, isMobile},
    } = this.props;
    const {modalGalleryCurrentIndex, isModalGalleryOpen} = this.state;
    const C = isMobile ? ModalGalleryMobile : ModalGalleryDesktop;
    const props: ModalGalleryBaseProps = {
      media: product.media,
      currentIndex: modalGalleryCurrentIndex,
      handleNavigateTo: this.handleNavigateTo,
      handleClose: this.close,
      handleMount: this.onMount,
    };

    return <div data-hook="modal-gallery-on-dom">{isModalGalleryOpen && <C {...props} />}</div>;
  }

  public readonly noop = () => {
    //
  };

  public render(): JSX.Element {
    const {
      children,
      globals: {withModalGallery},
    } = this.props;

    return (
      <>
        {withModalGallery && this.renderModal()}
        {children({
          open: withModalGallery ? this.open : this.noop,
          isEnabled: withModalGallery,
        })}
      </>
    );
  }
}
