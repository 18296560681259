import React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import s from './ProductSku.scss';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export type ProductSkuProps = ProvidedGlobalProps & IProvidedTranslationProps;

@withGlobalProps
@withTranslations('globals.texts')
export class ProductSku extends React.Component<ProductSkuProps> {
  public render(): JSX.Element {
    const {t} = this.props;
    const {product} = this.props.globals;
    const sku = (this.props.globals.selectedVariant || ({} as IOptionSelectionVariant)).sku || product.sku;
    const text = sku ? `${t('SKU_LABEL')} ${sku}` : '';
    return (
      <div className={s.sku} data-hook="sku">
        {text}
      </div>
    );
  }
}
